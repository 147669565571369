import React from "react";
const Footer = () => {
  return (
    <>
      <div className="bg-black ">
        <p className="mb-0 text-white text-center p-1 fs-sm">
          Copyright © 2022. All right reserved.
        </p>
      </div>
    </>
  );
};
export default Footer;
