import React from "react";
import { Link } from "react-router-dom";
import MainLogo from "../../images/logo.png";
const Logo = () => {
  return (
    <>
      <div className="d-flex">
        <Link className="text-center d-inline-block" to={"/"}>
          <img width={"270px"} src={MainLogo} alt="GGI" />
        </Link>
      </div>
    </>
  );
};
export default Logo;
