import types from './memberTrackingActionTypes';

export const getMemberTracking = (values) => ({
  type: types.GET_MEMBER_TRACKING,
  payload: values,
});

export const showMemberTracking = (values) => ({
  type: types.SHOW_MEMBER_TRACKING,
  payload: values,
});
