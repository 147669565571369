import React, { useState } from 'react';

const Location = ({ lat, lng }) => {
  const google = window.google;
  const geocoder = new google.maps.Geocoder();
  const [address, setAddress] = useState('');

  geocoder.geocode({ location: { lat: parseFloat(lat), lng: parseFloat(lng) } }).then((res) => {
    if (res.results[0]) {
      setAddress(res.results[0].formatted_address)
    }
  }).catch((e) => console.log("Geocoder failed due to: ", e));

  return <span className='w-100'>{address}</span>;
};

export default Location;
