import { all, call } from 'redux-saga/effects';

import { authSagas } from './auth/authSagas';
import { userRoleSagas } from './userRole/roleSagas';
import { memberSagas } from './member/memberSagas';
import { memberEventSagas } from './memberEvent/memberEventSagas';
import { memberTrackingSagas } from './memberTracking/memberTrackingSagas';
import { notificationSagas } from './notification/notificationSagas';
import { alertSagas } from './alert/alertSagas';

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(userRoleSagas),
    call(memberSagas),
    call(memberEventSagas),
    call(memberTrackingSagas),
    call(notificationSagas),
    call(alertSagas),
  ]);
}
