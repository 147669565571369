import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import Badge from '@mui/material/Badge';
import ListNotification from './ListNotification';
import NotifyIcon from '../../images/notify.png';
import './notification.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../store/notification/notificationActions';
import { AUTHORIZATION_KEY } from '../../utils/configs';
import { EVENT_SOCKET } from '../../Constant';
import { socket } from '../../socket';

const Notification = () => {
  const dispatch = useDispatch();

  const userId = localStorage.getItem(AUTHORIZATION_KEY.USER_ID);

  const [countNotifications, setCountNotifications] = useState(null);

  const [notifications, readNotify] = useSelector((state) => [
    state.notificationReducer.notifications,
    state.notificationReducer.readNotification,
  ]);

  const [isOpenNotify, setIsOpenNotify] = useState(false);

  useEffect(() => {
    if (readNotify.data.success && readNotify.data?.result?.read) {
      dispatch(getNotifications({ userId: userId }));
    }
  }, [readNotify]);

  useEffect(() => {
    dispatch(getNotifications({ userId: userId }));

    socket.on(EVENT_SOCKET.NOTIFICATION, (data) => {
      dispatch(getNotifications({ userId: userId }));
    });

    return () => {
      socket.off(EVENT_SOCKET.NOTIFICATION);
    };
  }, []);

  useEffect(() => {
    if (notifications.data.length > 0) {
      const countNotify = notifications.data.filter((item) => !item.read);
      setCountNotifications(countNotify.length);
    }
  }, [notifications]);

  const onChangeOpenNotify = (open) => {
    setIsOpenNotify(open);
  };

  return (
    <>
      <div className="notification-main">
        <Dropdown
          open={isOpenNotify}
          onOpenChange={onChangeOpenNotify}
          dropdownRender={() => <ListNotification setOpenDropdown={setIsOpenNotify} />}
          trigger={['click']}
        >
          <Badge color="warning" badgeContent={countNotifications}>
            <img width={'22px'} className="cursor-pointer" src={NotifyIcon} alt="Notify" />
          </Badge>
        </Dropdown>
      </div>
    </>
  );
};
export default Notification;
