import types from './memberActionTypes';

export const getMember = (values) => ({
  type: types.GET_MEMBER,
  payload: values,
});

export const showMember = (values) => ({
  type: types.SHOW_MEMBER,
  payload: values,
});

export const getSearchMembers = (values) => ({
  type: types.GET_SEARCH_MEMBERS,
  payload: values,
});

export const showSearchMembers = (values) => ({
  type: types.SHOW_SEARCH_MEMBERS,
  payload: values,
});

export const showMemberMarker = (values) => ({
  type: types.SHOW_MEMBER_MARKER,
  payload: values,
});
