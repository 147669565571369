import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import React, { useState } from 'react';
import { InfoWindow } from 'react-google-maps';
import { useDispatch } from 'react-redux';
import imageDefault from '../../../images/avatar/woman.svg';
import { showMemberMarker } from '../../../store/member/memberActions';
import { getMemberTracking } from '../../../store/memberTracking/memberTrackingActions';
import Location from '../Location';
import TimelineData from '../TimelineData';
import TrackerInfo from './TrackerInfo';

const InfoWindowData = ({ setDataInfoWindow, setActiveMarker }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState(false);

  const memberEvent = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const memberTracking = () => {
    dispatch(
      getMemberTracking({
        trackingID: setDataInfoWindow.id,
        currentDate: '',
      })
    );
    //console.log('memberTracking', setDataInfoWindow.id);
  };

  const handleCloseInfoWindow = () => {
    setActiveMarker(null);
    dispatch(showMemberMarker(null));
    // dispatch(showMemberTracking(INITIAL_STATE_REDUCER.OBJECT));
  };

  return (
    <div>
      <InfoWindow onCloseClick={handleCloseInfoWindow}>
        <div id="infoWindow-map">
          <div className="row">
            <div className="d-flex info_bl mb-2">
              <div className="img me-2">
                <p className="border border-black rounded-circle mb-1">
                  <img
                    className="rounded-circle object-cover"
                    width="100"
                    height={'100'}
                    src={setDataInfoWindow.image ? setDataInfoWindow.image : imageDefault}
                    alt="Avatar"
                  />
                </p>
                <p className="mb-0 text-center">{setDataInfoWindow.name}</p>
              </div>
              <div className="txt w-100">
                <p className="mb-15px fw-bold pt-1">{setDataInfoWindow.name}</p>
                {/* <p className="mb-15px">
                  Địa chỉ: 
                </p> */}

                {/* Tracker */}
                <TrackerInfo data={setDataInfoWindow?.user_info} />

                <ul className="d-flex info_icon text-center">
                  <li className="cursor-pointer" onClick={memberTracking}>
                    <span className="icon01">
                      <img width="20" src="/assets/directions_white.png" alt="directions" />
                    </span>
                    <span className="d-block">Đường đi</span>
                  </li>
                  <li className="cursor-pointer" onClick={memberEvent('right', true)}>
                    <span className="icon02">
                      <img width="20" src="/assets/event.svg" alt="Event" />
                    </span>
                    <span className="d-block">Sự kiện</span>
                  </li>
                  <li>
                    <span className="icon01">
                      <img width="17" src="/assets/share.svg" alt="share" />
                    </span>
                    <span className="d-block">Gửi thông tin</span>
                  </li>
                </ul>
              </div>
            </div>
            <p className="txt02 mb-sm">
              <span>
                <img width="25" src="/assets/location.svg" alt="Location" />
              </span>
              {<Location lat={setDataInfoWindow.lat} lng={setDataInfoWindow.lng} />}
              {/* {setDataInfoWindow.address} */}
            </p>
            <p className="txt02 mb-0">
              <span className="text-center">
                <img width="17" src="/assets/phone.svg" alt="Phone" />
              </span>
              {setDataInfoWindow.phone}
            </p>
          </div>
        </div>
      </InfoWindow>
      {/* <TrackingData trackingID={setDataInfoWindow.id} /> */}
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={memberEvent('right', false)}
        //hideBackdrop={true}
      >
        <div className="pt-9 px-2 w-450px event_popup">
          <p
            className="mb-0 position-fixed end-4 cursor-pointer close_icon"
            onClick={memberEvent('right', false)}
          >
            <CloseIcon />
          </p>
          <h3 className="fs-3 mt-1 text-center mb-3">Sự kiện</h3>
          <TimelineData eventID={setDataInfoWindow.id} />
        </div>
      </Drawer>
    </div>
  );
};

export default InfoWindowData;
