import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Polyline } from 'react-google-maps';
import { useSelector } from 'react-redux';

const TrackingData = ({ trackingID }) => {
  const [trackingData, setTrackingData] = useState([]);
  const [path, setPath] = useState([]);

  const [childrenTracking] = useSelector((state) => [state.memberTrackingReducer.items]);

  useEffect(() => {
    if (childrenTracking.data.error_code === 0) {
      const data = childrenTracking.data?.results?.data;
      if (data.length > 0) {
        setTrackingData(childrenTracking.data?.results?.data);
      } else {
        setTrackingData([]);
      }
    }
  }, [childrenTracking]);

  useEffect(() => {
    let result = [];
    if (trackingData.length > 0) {
      trackingData.map((data) => {
        return result.push(data.position);
      });
      setPath(result);
    }
  }, [trackingData]);

  const optionsPolyline = {
    strokeColor: 'red',
    strokeOpacity: 0.8,
    strokeWeight: 10,
    fillColor: '#085daa',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: true,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  return (
    <div>
      {path.length > 0 && (
        <div className="position-relative">
          <Polyline path={path} options={optionsPolyline} />
        </div>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={childrenTracking.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default TrackingData;
