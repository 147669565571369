import 'antd/dist/antd.min.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashBoard from './pages/DashBoard';
import SignIn from './pages/SignIn';
import './scss/App.scss';
import { logInSuccess, logOut } from './store/auth/authActions';
import { getProfileUserLogin } from './store/userRole/roleActions';
import { AUTHORIZATION_KEY } from './utils/configs';

function App() {
  const [auth, childrenStatus] = useSelector((state) => [state.auth, state.memberReducer.items]);

  const dispatch = useDispatch();
  let accessToken = localStorage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN);
  let tokenExpired = localStorage.getItem(AUTHORIZATION_KEY.TOKEN_USER_EXPIRE);

  useEffect(() => {
    if (accessToken) {
      const today = new Date();

      const parseDate = (value) => new Date(value);

      const dateDiff = (dateDiff, currentDate) => Math.round(dateDiff - currentDate);

      if (dateDiff(parseDate(tokenExpired), today) <= 0) {
        localStorage.clear();
        dispatch(logOut());
      } else {
        dispatch(logInSuccess(accessToken));
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (childrenStatus.isError === true) {
      window.localStorage.clear();
      window.location.href = '/';
    }
  }, [childrenStatus]);

  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }

    dispatch(getProfileUserLogin());
  }, [auth]);

  return !auth.currentUser ? (
    <SignIn />
  ) : (
    <>
      <DashBoard />
    </>
  );
}

export default App;
