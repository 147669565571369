import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import { getMemberEvent } from '../../../store/memberEvent/memberEventActions';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

const TimelineData = ({ eventID }) => {
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getMemberEvent({eventID}));
  }, [dispatch, eventID]);

  const [childrenEvent, checkLoading] = useSelector((state) => [state.memberEventReducer.items.data, state.memberEventReducer.items]);
  useEffect(() => {
    if(childrenEvent.error_code === 0){
      const data = childrenEvent.results.data;
      if(data.length > 0){
        setEventData(childrenEvent.results.data[0].events)
      }else{
        setEventData([]);
      }
    }
  }, [dispatch, childrenEvent]);

  useEffect(() => {
    if(checkLoading.isLoading === false && checkLoading.isError === false){
      setLoading(false);
    }else{
      setLoading(true);
    }
  },[checkLoading])

  return (
    <div className='position-relative'>
      <Timeline position="right" className="text-left h-100 p-0">
        {loading && (
          <div className='text-center event_progressIcon'>
            <CircularProgress
              size={45}
              sx={{
                color: '#000',
                position: 'absolute',
                top: "50%",
                left: "50%",
                zIndex: 2,
              }}
            />
          </div>
        )}
        {/* <TimelineItem>
          <TimelineSeparator>
            <BatteryCharging20Icon className="text-red" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            Pin yếu
            <br />
            <span className="text-gray-200">16:30</span>
          </TimelineContent>
        </TimelineItem> */}
        {eventData.length > 0 ? (
          eventData.map((data) => (
            <TimelineItem key={data.id}>
              <TimelineSeparator>
                <WarningAmberIcon className={`timeline_icon ${data.type === "1" ? `text-red` : 'text-secondary'}`} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className='timeline_bd'>
                {data.content}<br />
                <span className="text-gray-200">{moment(data.event_date).format('H:mm')}</span>
              </TimelineContent>
            </TimelineItem>
          ))
        ):(
          <p>Không có sự kiện nào cả!!!!</p>
        )}
      </Timeline>
    </div>
  );
};

export default TimelineData;