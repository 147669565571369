import types from './notificationActionTypes';

export const getNotifications = (values) => ({
  type: types.GET_NOTIFICATIONS,
  payload: values,
});

export const showNotifications = (values) => ({
  type: types.SHOW_NOTIFICATIONS,
  payload: values,
});

export const createNotification = (values) => ({
  type: types.CREATE_NOTIFICATION,
  payload: values,
});

export const showCreateNotification = (values) => ({
  type: types.SHOW_CREATE_NOTIFICATION,
  payload: values,
});

export const readNotification = (values) => ({
  type: types.READ_NOTIFICATION,
  payload: values,
});

export const showReadNotification = (values) => ({
  type: types.SHOW_READ_NOTIFICATION,
  payload: values,
});
