import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../gateway/Instance';

import { showMemberTracking } from '../memberTrackingActions';

import types from '../memberTrackingActionTypes';

const getMemberTrackingAPI = async ({ trackingID, currentDate }) => {
  const response = apiInstance.get(`/get-children-tracking`, {
    params: {
      children_id: trackingID,
      date: '',
    },
  });
  return response;
};

export function* getMemberTrackingCredentials({ payload }) {
  try {
    yield put(showMemberTracking({ isLoading: true, data: [], isError: false }));
    const result = yield call(getMemberTrackingAPI, payload);
    yield put(showMemberTracking({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showMemberTracking({ isLoading: false, data: [], isError: true }));
    throw error;
  }
}

export function* onMemberTrackingStart() {
  yield takeLatest(types.GET_MEMBER_TRACKING, getMemberTrackingCredentials);
}

export function* memberTrackingSagas() {
  yield all([call(onMemberTrackingStart)]);
}
