import React, { useState, useEffect } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from 'react-google-maps';
import InfoWindowData from './InfoWindowData';
import { useDispatch, useSelector } from 'react-redux';
import { getMember } from '../../store/member/memberActions';
import imageDefault from '../../images/avatar/iconuser.svg';
import { showMemberTracking } from '../../store/memberTracking/memberTrackingActions';
import { EVENT_SOCKET, INITIAL_STATE_REDUCER } from '../../Constant';
import './map.scss';
import { socket } from '../../socket';
import {
  getAlerts,
  markAlertAsReadByUser,
  setNewAlert,
  showAlerts,
} from '../../store/alert/alertActions';
import { objectIsEmpty } from '../../utils/supportFunction';
import TrackingData from './TrackingData';

const Map = () => {
  const dispatch = useDispatch();
  const [markerData, setMarkerData] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [activeMarkerTracking, setActiveMarkerTracking] = useState(null);
  const [defaultZoom, setDefaultZoom] = useState(16);
  //const [defaultCenter, setDefaultCenter] = useState(null);
  let accessToken = localStorage.getItem('access_token');

  const isFirefox =
    typeof InstallTrigger !== 'undefined' && window.navigator.userAgent.indexOf('Firefox') !== -1;

  const [childrenData, memberMarker, profileUser, alerts, newAlert] = useSelector((state) => [
    state.memberReducer.items,
    state.memberReducer.memberMarker,
    state.usersReducer.profileUser,
    state.alertReducer.alerts,
    state.alertReducer.newAlert,
  ]);

  useEffect(() => {
    if (accessToken) {
      dispatch(getMember());
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (!objectIsEmpty(profileUser.data)) {
      dispatch(getAlerts({ userId: profileUser.data.id }));
    }
  }, [dispatch, profileUser]);

  useEffect(() => {
    if (memberMarker) {
      setActiveMarker(memberMarker);
    }
  }, [memberMarker]);

  useEffect(() => {
    if (childrenData.isLoading === false && childrenData.isError === false) {
      setMarkerData(childrenData?.data);
      //setDefaultCenter(childrenData.data[0].position)
    }
  }, [childrenData]);

  useEffect(() => {
    if (!objectIsEmpty(newAlert)) {
      dispatch(showAlerts({ ...INITIAL_STATE_REDUCER.ARRAY, data: [newAlert, ...alerts.data] }));
    }
  }, [newAlert]);

  // SOCKET
  useEffect(() => {
    socket.on(EVENT_SOCKET.ALERT, (res) => {
      dispatch(setNewAlert(res.alert));
    });

    socket.on(EVENT_SOCKET.ALERT_OFF, (res) => {
      if (res.alert?.length) {
        let alertTemp = alerts.data;

        for (const idAlert of res.alert) {
          alertTemp = alertTemp.map((item) => {
            if (item._id === idAlert) {
              return { ...item, read: true };
            }

            return item;
          });
        }

        dispatch(showAlerts({ ...INITIAL_STATE_REDUCER.ARRAY, data: alertTemp }));
      }
    });

    return () => {
      socket.off(EVENT_SOCKET.ALERT);
      socket.off(EVENT_SOCKET.ALERT_OFF);
    };
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker !== activeMarker) {
      //? dispatch before setState!!!
      dispatch(showMemberTracking(INITIAL_STATE_REDUCER.OBJECT));
      setActiveMarkerTracking(marker);
    }

    setActiveMarker(marker);

    const alertMarker = alerts.data.find((item) => parseInt(item.childId) === marker && !item.read);

    if (alertMarker) {
      const newAlertsTemp = alerts.data.map((item) => {
        if (item._id === alertMarker._id && !item.read) {
          return { ...item, read: true };
        }

        return item;
      });

      dispatch(showAlerts({ ...INITIAL_STATE_REDUCER.ARRAY, data: newAlertsTemp }));
      dispatch(markAlertAsReadByUser({ userId: alertMarker.userId, childId: alertMarker.childId }));
    }
  };

  const renderCustomMarker = (id) => {
    const childrenWarning = alerts.data.find((item) => parseInt(item.childId) === id && !item.read);

    if (!childrenWarning) {
      return '#custom_marker';
    }

    switch (childrenWarning.status) {
      case 'warning':
        return '#warning#pulse#custom_marker';
      case 'danger':
        return '#danger#pulse#custom_marker';
      case 'safe':
        return '#safe#pulse#custom_marker';
      case 'unknown':
        return '#unknown#pulse#custom_marker';
      default:
        return '#custom_marker';
    }
  };

  const addAnimation = (id) => {
    if (!isFirefox) {
      return null;
    }

    const childrenWarning = alerts.data.find((item) => parseInt(item.childId) === id && !item.read);

    if (!childrenWarning) {
      return null;
    }

    return window.google.maps.Animation.BOUNCE;
  };

  return (
    <div>
      <GoogleMap defaultZoom={16} defaultCenter={{ lat: 10.803632, lng: 106.641642 }}>
        {markerData?.map((data) => {
          return (
            <Marker
              key={data?.id}
              icon={{
                url: data?.image
                  ? data?.image + renderCustomMarker(data.id)
                  : imageDefault + renderCustomMarker(data.id),
                scaledSize: new window.google.maps.Size(23, 23),
                shape: { coords: [17, 17, 18], type: 'circle' },
              }}
              position={data?.position}
              onClick={() => handleActiveMarker(data?.id)}
              draggable={true}
              animation={addAnimation(data.id)}
            >
              {activeMarker === data?.id && (
                <InfoWindowData setDataInfoWindow={data} setActiveMarker={setActiveMarker} />
              )}

              {activeMarkerTracking === data?.id && <TrackingData trackingID={data.id} />}
            </Marker>
          );
        })}
      </GoogleMap>
    </div>
  );
};

export default withScriptjs(withGoogleMap(Map));
