import { INITIAL_STATE_REDUCER } from '../../Constant';
import types from './memberActionTypes';

const initialState = {
  items: INITIAL_STATE_REDUCER.ARRAY_LOADING,
  searchMembers: INITIAL_STATE_REDUCER.ARRAY,
  memberMarker: null,
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MEMBER:
      return {
        ...state,
        items: action.payload,
      };
    case types.SHOW_SEARCH_MEMBERS:
      return {
        ...state,
        searchMembers: action.payload,
      };
    case types.SHOW_MEMBER_MARKER:
      return {
        ...state,
        memberMarker: action.payload,
      };
    default:
      return state;
  }
};

export default memberReducer;
