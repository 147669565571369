import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_CONFIGS, AUTHORIZATION_KEY } from '../../../utils/configs';
import { notification } from 'antd';

import { logInFailure, logInSuccess } from '../authActions';

import types from '../authActionTypes';

const logIn = async (params) => {
  try {
    const response = await axios.post(`${API_CONFIGS.API_BASE_URL}/api/login`, {
      email: params.email,
      password: params.password,
      role: params.role,
    });
    if (response.status === 200) {
      localStorage.setItem(AUTHORIZATION_KEY.ACCESS_TOKEN, response.data.data.token.access_token);
      localStorage.setItem(
        AUTHORIZATION_KEY.TOKEN_USER_EXPIRE,
        response.data.data.token.expired_at
      );
      localStorage.setItem(AUTHORIZATION_KEY.USER_ID, response.data.data.id);
      notification.success({
        message: 'Thành công',
        description: 'Đăng nhập thành công!!!',
        placement: 'bottomRight',
      });
    }
    return { token: response.data.data.token.access_token };
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: 'Email hoặc mật khẩu không đúng',
      placement: 'bottomRight',
    });
  }
};

export function* logInWithCredentials({ payload: { email, password, role } }) {
  try {
    const userToken = yield logIn({ email, password, role });
    yield put(logInSuccess(userToken));
  } catch (error) {
    yield put(logInFailure(error));
  }
}

export function* onLogInStart() {
  yield takeLatest(types.LOG_IN_START, logInWithCredentials);
}

export function* authSagas() {
  yield all([call(onLogInStart)]);
}
