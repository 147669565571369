import React from 'react';
import Logo from '../Logo';
import LampIcon from '../../images/lamp.png';
import Avatar from '../../images/avatar/avatarAdmin.jpg';
import Badge from '@mui/material/Badge';
import { Dropdown, Menu } from 'antd';
import Notification from '../Notification';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import './header.scss';
import { useSelector } from 'react-redux';
import Search from '../Search';

const logout = () => {
  window.localStorage.clear();
  window.location.href = '/';
};

const menu = (
  <Menu
    items={[
      {
        label: (
          <span className="cursor-pointer" onClick={logout}>
            <LogoutIcon className="fs-sm me-sm" />
            Logout
          </span>
        ),
        key: '0',
      },
    ]}
  />
);

const searchForm = (
  <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      placeholder="Search"
      inputProps={{ 'aria-label': 'Search' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
  </Paper>
);

const Header = () => {
  const [profileUser] = useSelector((state) => [state.usersReducer.profileUser.data]);

  return (
    <>
      <div
        className="dashboard-head bg-white px-15px w-100 shadow-lg position-fixed top-0 start-0 z-5"
        style={{ height: '70px' }}
      >
        <div className="d-flex justify-content-between align-items-center pt-1">
          <Logo />
          <div className="d-flex align-items-center">
            {/* Search children */}
            <Search />

            <p className="mb-0 ms-2">
              <Badge color="warning" variant="dot">
                <img
                  //onClick={toggleDrawer('right', true)}
                  width={'20px'}
                  className="cursor-pointer"
                  src={LampIcon}
                  alt="Lamp"
                />
              </Badge>
            </p>
            <p className="mb-0 ms-2">
              <Notification />
            </p>
            <div className="ms-3 profile-top">
              <Dropdown overlay={menu} trigger={['click']}>
                <p className="d-flex align-items-center mb-0 cursor-pointer">
                  <span className="text-gray-900 me-sm">{profileUser.name}</span>
                  <img
                    width={'30px'}
                    className="rounded-circle"
                    src={profileUser.image ? profileUser.image : Avatar}
                    alt="Avatar"
                  />
                </p>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
