import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstanceChat from '../../../gateway/InstanceChat';

import {
  showCreateNotification,
  showNotifications,
  showReadNotification,
} from '../notificationActions';

import types from '../notificationActionTypes';

const getNotificationsAPI = async (params) => {
  // const response = apiInstanceChat.get(`/webControlNotification`, { params });
  const response = apiInstanceChat.get(`/webControlNotification`, {
    params,
  });
  return response;
};

const createNotificationsAPI = async (params) => {
  const response = apiInstanceChat.post(`/webControlNotification`, params);
  return response;
};

const readNotificationsAPI = async (notificationId) => {
  const response = apiInstanceChat.patch(`/webControlNotification/${notificationId}/read`);
  return response;
};

export function* getNotificationsCredentials({ payload }) {
  try {
    yield put(showNotifications({ isLoading: true, data: {}, isError: false }));
    const result = yield call(getNotificationsAPI, payload);
    yield put(showNotifications({ isLoading: false, data: result.notifications, isError: false }));
  } catch (error) {
    yield put(showNotifications({ isLoading: false, data: {}, isError: true }));
    throw error;
  }
}

export function* createNotificationCredentials({ payload }) {
  try {
    yield put(showCreateNotification({ isLoading: true, data: [], isError: false }));
    const result = yield call(createNotificationsAPI, payload);
    yield put(showCreateNotification({ isLoading: false, data: result.data, isError: false }));
  } catch (error) {
    yield put(showCreateNotification({ isLoading: false, data: [], isError: true }));
    throw error;
  }
}

export function* readNotificationCredentials({ payload }) {
  try {
    yield put(showReadNotification({ isLoading: true, data: [], isError: false }));
    const result = yield call(readNotificationsAPI, payload);
    yield put(showReadNotification({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showReadNotification({ isLoading: false, data: [], isError: true }));
    throw error;
  }
}

export function* onNotificationStart() {
  yield takeLatest(types.GET_NOTIFICATIONS, getNotificationsCredentials);
  yield takeLatest(types.CREATE_NOTIFICATION, createNotificationCredentials);
  yield takeLatest(types.READ_NOTIFICATION, readNotificationCredentials);
}

export function* notificationSagas() {
  yield all([call(onNotificationStart)]);
}
