import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import './search.scss';
import { IconButton, InputBase, Paper, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { getSearchMembers, showMemberMarker } from '../../store/member/memberActions';
import imageDefault from '../../images/avatar/woman.svg';
import SearchImg from '../../images/search.png';

const Search = () => {
  const dispatch = useDispatch();

  const [members] = useSelector((state) => [state.memberReducer.searchMembers]);

  useEffect(() => {
    if (!members.isLoading) {
      setSearchMemberResult(members.data);
    }
  }, [members]);

  const [valueInputSearch, setInputSearch] = useState('');
  const [searchMemberResult, setSearchMemberResult] = useState([]);
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const onChangeOpenSearch = (open) => {
    setIsOpenSearch(open);
  };

  const onChangeSearch = (event) => {
    setInputSearch(event.target.value);
  };

  const handleBtnSearch = (e) => {
    e.preventDefault();

    if (!valueInputSearch) {
      return;
    }

    dispatch(getSearchMembers({ search: valueInputSearch }));
  };

  const renderHeight = (length, minHeight) => {
    if (length < 5) {
      return {
        height: length * minHeight + 10,
      };
    }

    return {
      height: 4 * minHeight + 10,
      overflowY: 'scroll',
    };
  };

  const memberActiveMarker = (member) => {
    setIsOpenSearch(false);
    dispatch(showMemberMarker(member.id));
  };

  const renderSearchResult = () => {
    if (members.isLoading) {
      return (
        <div className="d-flex align-items-start px-2 py-1 w-100">
          <div>
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
          </div>
          <div className="ms-1 w-100">
            <Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </div>
        </div>
      );
    }

    return searchMemberResult.length ? (
      <div className="py-1" style={renderHeight(searchMemberResult.length, 86)}>
        {searchMemberResult.map((member, index) => (
          <div
            key={index}
            className="d-flex align-items-start px-2 py-1 cursor-pointer"
            onClick={() => memberActiveMarker(member)}
          >
            <img
              width={'35px'}
              className="cursor-pointer rounded-circle"
              src={member.image ? member.image : imageDefault}
              alt="Search"
            />
            <div className="d-flex flex-column ms-1 item-search">
              <span className="name-member">{member.name}</span>
              <span>{member.phone}</span>
              <span>{member.email}</span>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center p-2">
        <span style={{ color: '#a0a0a0' }}>Không có dữ liệu!</span>
      </div>
    );
  };

  const dropdownRenderSearch = () => {
    return (
      <Paper sx={{ width: 400 }}>
        <Paper
          component={'form'}
          onSubmit={handleBtnSearch}
          sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ 'aria-label': 'Search' }}
            onChange={onChangeSearch}
          />
          <IconButton
            type="submit"
            onClick={handleBtnSearch}
            sx={{ p: '10px' }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>

        {/* Search result */}
        {renderSearchResult()}
      </Paper>
    );
  };

  return (
    <Dropdown
      open={isOpenSearch}
      onOpenChange={onChangeOpenSearch}
      dropdownRender={dropdownRenderSearch}
      trigger={['click']}
      autoFocus={true}
    >
      <p className="mb-0">
        <img width={'25px'} className="cursor-pointer" src={SearchImg} alt="Search" />
      </p>
    </Dropdown>
  );
};
export default Search;
