import types from './roleActionTypes';

export const getUserRole = (values) => ({
  type: types.GET_USER_ROLE,
  payload: values,
});

export const showUserRole = (values) => ({
  type: types.SHOW_USER_ROLE,
  payload: values,
});

export const getProfileUserLogin = (values) => ({
  type: types.GET_PROFILE_USER_LOGIN,
  payload: values,
});

export const setProfileUserLogin = (values) => ({
  type: types.SET_PROFILE_USER_LOGIN,
  payload: values,
});
