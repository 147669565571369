import types from './alertActionTypes';

export const getAlerts = (values) => ({
  type: types.GET_ALERTS,
  payload: values,
});

export const showAlerts = (values) => ({
  type: types.SHOW_ALERTS,
  payload: values,
});

export const setNewAlert = (values) => ({
  type: types.SET_NEW_ALERT,
  payload: values,
});

export const markAlertAsRead = (values) => ({
  type: types.MARK_ALERT_AS_READ,
  payload: values,
});

export const markAlertAsReadByUser = (values) => ({
  type: types.MARK_ALERT_AS_READ_BY_USER,
  payload: values,
});
