import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../gateway/Instance';

import { showMember, showSearchMembers } from '../memberActions';

import types from '../memberActionTypes';

const getMemberAPI = async (params) => {
  const response = apiInstance.get(`/get-children`, { params });
  return response;
};

export function* getMemberCredentials({ payload }) {
  try {
    yield put(showMember({ isLoading: true, data: [], isError: false }));
    const result = yield call(getMemberAPI, payload);
    yield put(showMember({ isLoading: false, data: result.data, isError: false }));
  } catch (error) {
    yield put(showMember({ isLoading: false, data: [], isError: true }));
    throw error;
  }
}

export function* getSearchMembersCredentials({ payload }) {
  try {
    yield put(showSearchMembers({ isLoading: true, data: [], isError: false }));
    const result = yield call(getMemberAPI, payload);
    yield put(showSearchMembers({ isLoading: false, data: result.data, isError: false }));
  } catch (error) {
    yield put(showSearchMembers({ isLoading: false, data: [], isError: true }));
    throw error;
  }
}

export function* onMemberStart() {
  yield takeLatest(types.GET_MEMBER, getMemberCredentials);
  yield takeLatest(types.GET_SEARCH_MEMBERS, getSearchMembersCredentials);
}

export function* memberSagas() {
  yield all([call(onMemberStart)]);
}
