import React from 'react';
import LoginArea from './LoginArea';
import LogoWhite from '../../images/logo-white.png';
import './login.scss';

const SignIn = () => {
  return (
    <>
      <div className="signIn-bg d-flex align-items-center w-100 h-100vh justify-content-center flex-column">
        <div className="text-white mb-2">
          <img src={LogoWhite} alt="GGI" />
        </div>
        <div className="login-form text-white">
          <h2 className="text-center fs-3 mb-3 text-white">Sign In</h2>
          <LoginArea />
        </div>
      </div>
    </>
  );
};
export default SignIn;
