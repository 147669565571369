import React from 'react';
import { List } from 'antd';
import '../notification.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { readNotification } from '../../../store/notification/notificationActions';

const ListNotification = ({ setOpenDropdown }) => {
  const dispatch = useDispatch();

  const [notifications] = useSelector((state) => [state.notificationReducer.notifications]);

  const formatDate = (date) => {
    if (!date) {
      return '-';
    }

    return moment(date).format('DD-MM-YYYY HH:mm');
  };

  const handleReadNotification = (notificationId, read) => {
    if (read) {
      return;
    }

    dispatch(readNotification(notificationId));
    setOpenDropdown(false);
  };

  return (
    <>
      <div
        className="notification-list bg-white rounded-2 shadow-lg px-15px pb-1 overflow-auto"
        style={{ width: 400, height: 400 }}
      >
        {notifications.data.length > 0 ? (
          <List
            header={<div>Thông báo</div>}
            dataSource={notifications.data}
            renderItem={(item) => (
              <List.Item
                onClick={() => handleReadNotification(item._id, item.read)}
                className="p-1 mb-1 rounded cursor-pointer"
                style={{ backgroundColor: item.read ? '#fff' : '#e2f0ff' }}
              >
                <List.Item.Meta
                  title={<span>{item.message}</span>}
                  description={formatDate(item.createdAt)}
                />
              </List.Item>
            )}
          />
        ) : (
          <span
            className="d-flex justify-content-center align-items-center fs-6 h-100"
            style={{ color: '#a0a0a0' }}
          >
            Không có thông báo!
          </span>
        )}
      </div>
    </>
  );
};
export default ListNotification;
