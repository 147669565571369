import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Map from '../../components/Map/';
import { API_CONFIGS } from '../../utils/configs';
import { socket } from '../../socket';

const DashBoard = () => {
  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="dashboard-map">
      <Header />
      <div className="dashboard-map_content">
        <div style={{ height: '100%', width: '100%' }}>
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_CONFIGS.API_KEY}`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashBoard;
