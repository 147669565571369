import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { logInStart } from '../../../store/auth/authActions';
import Switch from '@mui/material/Switch';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { notification } from 'antd';

const LoginArea = () => {
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    role: 'web-control',
  });

  const dispatch = useDispatch();

  const handleChange = (event) => {
    if (event.target.value) {
      setErrorEmail(false);
      setErrorPassword(false);
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleSubmit = () => {
    if (values.email === '' && values.password === '') {
      setErrorEmail(true);
      setErrorPassword(true);
      notification.error({
        message: 'Lỗi',
        description: 'Vui lòng nhập email hoặc mật khẩu',
        placement: 'bottomRight',
      });
      return false;
    }
    if (values.email === '') {
      setErrorEmail(true);
      notification.error({
        message: 'Lỗi',
        description: 'Vui lòng nhập email',
        placement: 'bottomRight',
      });
      return false;
    }
    if (values.password === '') {
      setErrorPassword(true);
      notification.error({
        message: 'Lỗi',
        description: 'Vui lòng nhập mật khẩu',
        placement: 'bottomRight',
      });
      return false;
    }
    dispatch(logInStart(values));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form className="" autoComplete="off" onSubmit={handleSubmit}>
        <div className="col-12 mb-2">
          <label className="mb-1 fw-bold">
            Email Address<span className="text-red">*</span>
          </label>
          <TextField
            error={errorEmail}
            id="outlined-error"
            name="email"
            value={values.phone}
            fullWidth
            label="Enter Email"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mb-2">
          <label className="mb-1 fw-bold">
            Enter Password <span className="text-red">*</span>
          </label>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Enter Password</InputLabel>
            <OutlinedInput
              error={errorPassword}
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              required
              name="password"
              value={values.password}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </div>
        {/* <div className="col-12 mb-1">
          <div className="d-flex justify-content-between">
            <p className="d-flex">
              <Switch color="primary" defaultChecked size="small" />{' '}
              <span className="ps-sm fw-bold">Remember Me</span>
            </p>
            <p className="text-white">
              <span className="text-white fw-bold cursor-pointer">Forgot Password</span>
            </p>
          </div>
        </div> */}
        <Button
          color="primary"
          className="fw-bold w-100"
          variant="contained"
          size="large"
          startIcon={<LockOpenIcon />}
          onClick={handleSubmit}
        >
          Sign In
        </Button>
      </form>
    </>
  );
};
export default LoginArea;
