import types from './memberEventActionTypes';

export const getMemberEvent = (values) => ({
  type: types.GET_MEMBER_EVENT,
  payload: values,
});

export const showMemberEvent = (values) => ({
  type: types.SHOW_MEMBER_EVENT,
  payload: values,
});
