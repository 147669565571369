import { INITIAL_STATE_REDUCER } from '../../Constant';
import types from './memberTrackingActionTypes';

const initialState = {
  items: INITIAL_STATE_REDUCER.OBJECT,
};

const memberTrackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MEMBER_TRACKING:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};

export default memberTrackingReducer;
