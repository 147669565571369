import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_CONFIGS } from '../../../utils/configs';

import { setProfileUserLogin, showUserRole } from '../roleActions';

import types from '../roleActionTypes';
import apiInstance from '../../../gateway/Instance';
import { INITIAL_STATE_REDUCER } from '../../../Constant';

const getUserRoleAPI = async (params) => {
  let token = await localStorage.getItem('access_token');
  if (token !== null) {
    return axios.post(
      `${API_CONFIGS.API_BASE_URL}${API_CONFIGS.API_BASE_VER}/get-user-infomation`,
      params,
      { headers: { token } }
    );
  }
};

const getProfileUserLoginAPI = async () => {
  const response = await apiInstance.get('/profile');
  return response;
};

// ===============================CALL API==================================

export function* getUserRoleCredentials() {
  try {
    const result = yield call(getUserRoleAPI);
    yield put(showUserRole(result.data));
  } catch (error) {
    throw error;
  }
}

export function* getProfileUserLoginCredentials() {
  try {
    yield put(setProfileUserLogin(INITIAL_STATE_REDUCER.OBJECT_LOADING));
    const result = yield call(getProfileUserLoginAPI);
    yield put(setProfileUserLogin({ ...INITIAL_STATE_REDUCER.OBJECT, data: result.data }));
  } catch (error) {
    yield put(setProfileUserLogin(INITIAL_STATE_REDUCER.OBJECT_ERROR));
    throw error;
  }
}

export function* onUserRoleStart() {
  yield takeLatest(types.GET_USER_ROLE, getUserRoleCredentials);
  yield takeLatest(types.GET_PROFILE_USER_LOGIN, getProfileUserLoginCredentials);
}

export function* userRoleSagas() {
  yield all([call(onUserRoleStart)]);
}
