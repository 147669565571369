import { INITIAL_STATE_REDUCER } from '../../Constant';
import types from './roleActionTypes';

const initialState = {
  items: [],
  profileUser: INITIAL_STATE_REDUCER.OBJECT_LOADING,
};

const userRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_USER_ROLE:
      return {
        ...state,
        items: action.payload,
      };
    case types.SET_PROFILE_USER_LOGIN:
      return {
        ...state,
        profileUser: action.payload,
      };
    default:
      return state;
  }
};

export default userRoleReducer;
