import { combineReducers } from 'redux';

import auth from './auth/authReducer';
import usersReducer from './userRole/roleReducer';
import memberReducer from './member/memberReducer';
import memberEventReducer from './memberEvent/memberEventReducer';
import memberTrackingReducer from './memberTracking/memberTrackingReducer';
import notificationReducer from './notification/notificationReducer';
import alertReducer from './alert/alertReducer';

export default combineReducers({
  auth,
  usersReducer,
  memberReducer,
  memberEventReducer,
  memberTrackingReducer,
  notificationReducer,
  alertReducer,
});
