import types from './notificationActionTypes';

const initialState = {
  notifications: { isLoading: true, data: [], isError: false },
  createNotification: { isLoading: true, data: {}, isError: false },
  readNotification: { isLoading: true, data: {}, isError: false },
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case types.SHOW_CREATE_NOTIFICATION:
      return {
        ...state,
        createNotification: action.payload,
      };
    case types.SHOW_READ_NOTIFICATION:
      return {
        ...state,
        readNotification: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
