import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstanceChat from '../../../gateway/InstanceChat';
import types from '../alertActionTypes';
import { showAlerts } from '../alertActions';
import { INITIAL_STATE_REDUCER } from '../../../Constant';

const getAlertsAPI = async (params) => {
  const response = apiInstanceChat.get(`/webControlAlert`, { params });
  return response;
};

const markAlertAsReadAPI = async (idAlert) => {
  const response = apiInstanceChat.patch(`/webControlAlert/${idAlert}/read`);
  return response;
};

const markAlertAsReadByUserAPI = async (params) => {
  const response = apiInstanceChat.patch(`/webControlAlert/read`, params);
  return response;
};

// =================================================================

export function* getAlertsCredentials({ payload }) {
  try {
    yield put(showAlerts(INITIAL_STATE_REDUCER.ARRAY_LOADING));
    const result = yield call(getAlertsAPI, payload);
    yield put(showAlerts({ ...INITIAL_STATE_REDUCER.ARRAY, data: result.alerts }));
  } catch (error) {
    console.log('🚀 ~ file: index.js:22 ~ function*getAlertsCredentials ~ error:', error);
    yield put(showAlerts(INITIAL_STATE_REDUCER.ARRAY_ERROR));
    throw error;
  }
}

export function* markAlertAsReadCredentials({ payload }) {
  try {
    yield call(markAlertAsReadAPI, payload);
  } catch (error) {
    console.log('🚀 ~ file: index.js:35 ~ function*markAlertAsReadCredentials ~ error:', error);
    throw error;
  }
}

export function* markAlertAsReadByUserCredentials({ payload }) {
  try {
    yield call(markAlertAsReadByUserAPI, payload);
  } catch (error) {
    console.log(
      '🚀 ~ file: index.js:49 ~ function*markAlertAsReadByUserCredentials ~ error:',
      error
    );
    throw error;
  }
}

export function* onAlertStart() {
  yield takeLatest(types.GET_ALERTS, getAlertsCredentials);
  yield takeLatest(types.MARK_ALERT_AS_READ, markAlertAsReadCredentials);
  yield takeLatest(types.MARK_ALERT_AS_READ_BY_USER, markAlertAsReadByUserCredentials);
}

export function* alertSagas() {
  yield all([call(onAlertStart)]);
}
