import { INITIAL_STATE_REDUCER } from '../../Constant';
import types from './alertActionTypes';

const initialState = {
  alerts: INITIAL_STATE_REDUCER.ARRAY,
  newAlert: {},
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case types.SET_NEW_ALERT:
      return {
        ...state,
        newAlert: action.payload,
      };
    default:
      return state;
  }
};

export default alertReducer;
