import React from 'react';
import { objectIsEmpty } from '../../../../utils/supportFunction';

const TrackerInfo = ({ data }) => {
  if (!data || objectIsEmpty(data)) {
    return <></>;
  }

  const renderItemTracker = (value, unit) => {
    if (!value) {
      return <span className="tracking-index d-block text-center">-</span>;
    }

    return (
      <span>
        <span className="tracking-index">{value}</span>
        <span className="unit text-gray-900">{unit}</span>
      </span>
    );
  };

  return (
    <div className="tracking-info d-flex justify-content-between">
      <div>
        <p className="mb-sm label-index">Heart Rate</p>
        {renderItemTracker(data.userHeartRate?.value, 'bmp')}
      </div>
      <div>
        <p className="mb-sm label-index">Blood Pressure</p>
        {data.userBloodPressure?.sbp ? (
          <span>
            <span className="tracking-index">
              {`${data.userBloodPressure?.sbp}/${data.userBloodPressure?.dbp}`}
            </span>
            <span className="unit text-gray-900">mmHg</span>
          </span>
        ) : (
          <span className="tracking-index d-block text-center">-</span>
        )}
      </div>
      <div>
        <p className="mb-sm label-index">SPO2</p>
        {renderItemTracker(data.userSpo2?.spo2_avg, '%')}
      </div>
      <div>
        <p className="mb-sm label-index">BMI</p>
        {renderItemTracker(data.userBmi?.bmi, '')}
      </div>
    </div>
  );
};

export default TrackerInfo;
