import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../gateway/Instance';

import { showMemberEvent } from '../memberEventActions';

import types from '../memberEventActionTypes';

const getMemberEventAPI = async ({ eventID }) => {
  const response = apiInstance.get(`/get-children-event`, {
    params: {
      children_id: eventID,
    },
  });
  return response;
};

export function* getMemberEventCredentials({ payload }) {
  try {
    yield put(showMemberEvent({ isLoading: true, data: [], isError: false }));
    const result = yield call(getMemberEventAPI, payload);
    yield put(showMemberEvent({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showMemberEvent({ isLoading: false, data: [], isError: true }));
    throw error;
  }
}

export function* onMemberEventStart() {
  yield takeLatest(types.GET_MEMBER_EVENT, getMemberEventCredentials);
}

export function* memberEventSagas() {
  yield all([call(onMemberEventStart)]);
}
