const notificationActionTypes = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  SHOW_NOTIFICATIONS: 'SHOW_NOTIFICATIONS',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  SHOW_CREATE_NOTIFICATION: 'SHOW_CREATE_NOTIFICATION',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  SHOW_READ_NOTIFICATION: 'SHOW_READ_NOTIFICATION',
};

export default notificationActionTypes;
